import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { CSVLink } from "react-csv";
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import TFGCourtTable from './TFGCourtTable/TFGCourtTable';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import { deleteTFGCourt, getTFGCourts } from '../../../services/TFG/TFGService';
import TFGTermSelector from '../TFGTermSelector/TFGTermSelector';
import TFGCourtDialog from './TFGCourtDialog/TFGCourtDialog';
import { useNotification } from '../../../Utils/hooks';
import { dateUTCToLocale } from '../../../Utils/Date';
import '../TFGDefense/TFGDefense.scss';

const TFGCourt = () => {
  const [selectedTerm, setSelectedTerm] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [editing, setEditing] = useState(null);
  const [data, setData] = useState([]);
  const notification = useNotification();
  const confirm = useConfirm();

  const retrieveCourts = useCallback(async (term) => {
    try {
      setApplicationLoading(true);
      const result = await getTFGCourts(term);
      if (!result) throw new Error();
      setData(result);
    } catch {
      notification('error', 'Ha habido un error al obtener los tribunales de defensa');
    } finally {
      setApplicationLoading(false);
    }
  }, [notification]);

  useEffect(() => {
    if (selectedTerm) retrieveCourts(selectedTerm);
  }, [selectedTerm, retrieveCourts]);

  const csvData = useMemo(() => {
    const headers = ["Nombre del tribunal", "Tribunal", "Franjas horarias"];
    let rows = [];
  
    data.forEach(court => {
      rows.push([
        court.name,
        `${court.chairmanName} (Presidente), ${court.secretaryName} (Secretario), ${court.vocalName} (Vocal)`,
        court.timeSpans.map(
          timespan => `${dateUTCToLocale(timespan.start)} ${timespan.reserved ? '(Reservado)' : '(Libre)'}`
        ).join(', ')
      ])
    });

    return [headers, ...rows];
  }, [data]);

  const onHideDialog = () => {
    setShowDialog(false);
    setTimeout(() => setEditing(null), 250);
  }

  const onSuccess = (isEdit, result) => {
    setData(prev => {
      const newData = [...prev];
      if (!isEdit) newData.push(result);
      else {
        const index = newData.findIndex(trm => trm.id === result.id);
        if (~index) newData[index] = result;
      }
      return newData;
    })
  
    onHideDialog();
  }

  const onEditClick = id => {
    const entry = data.find(ent => ent.id === id);
    if (!entry) return;

    setEditing(entry);
    setShowDialog(true);
  }

  const onDeleteClick = id => {
    const entry = data.find(ent => ent.id === id);
    if (!entry) return;

    confirm({
      title: 'Eliminar tribunal de defensa',
      description: `Atención, esta acción eliminará el tribunal de defensa seleccionado, ¿estás seguro de que deseas realizar esta acción?`,
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          await deleteTFGCourt(id);
          setData(prev => prev.filter(area => area.id !== id))
          notification('success', 'El tribunal de defensa ha sido eliminado correctamente');
        } catch {
          notification('error', 'Ha habido un error al eliminar el tribunal de defensa');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const CSVButtonClass = classNames(
    'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary',
    { 'Mui-disabled': csvData.length <= 1 }
  );

  const TermSelector = <TFGTermSelector selectedTerm={selectedTerm} setSelectedTerm={setSelectedTerm} />;

  if (!selectedTerm) return TermSelector;

  return (
    <div className="tfg-court">
      {TermSelector}
      <TFGCourtDialog
        visible={showDialog}
        onHide={onHideDialog}
        editing={editing}
        onSuccess={onSuccess}
        selectedTerm={selectedTerm}
      />
      <div className="tfg-court__header">
        <CSVLink
          data={csvData}
          disabled={csvData.length <= 1}
          className={CSVButtonClass}
          style={{ marginRight: '1em' }}
          filename='Tribunales de defensa.csv'
        >
          Descargar CSV
        </CSVLink>
        <Button
          onClick={() => setShowDialog(true)}
          variant="contained"
          color="primary"
        >
          Crear nuevo tribunal
        </Button>
      </div>
      <TFGCourtTable
        data={data}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
    </div>
  )
}

export default TFGCourt;