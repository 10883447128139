import React, { useState, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, CircularProgress } from '@material-ui/core';
import InputText from '../../../../components/Inputs/RHK/InputText';
import InputSelectSimple from '../../../../components/Inputs/RHK/InputSelectSimple';
import InputAutoCompleteValue from '../../../../components/Inputs/RHK/InputAutoCompleteValue';
import InputAutoComplete from '../../../../components/Inputs/RHK/InputAutoCompleteValue/InputAutoCompleteValue';
import { setApplicationLoading } from '../../../../components/ApplicationLoading';
import TFGTermSelector from '../../TFGTermSelector/TFGTermSelector';
import { required } from '../../../../Utils/utils';
import { useNotification } from '../../../../Utils/hooks';
import { createTFGDefenseRequest, getTFGDefenseDirectors, getTFGDefenseStudentCourts, getTFGRequests, getTFGTerms, updateTFGDefenseRequest } from '../../../../services/TFG/TFGService';
import { dateToDayMonthYear, dateToHourMinute, dateToUTC } from '../../../../Utils/Date';

const TFGDefenseDialog = ({ visible, onHide, onSuccess, editing }) => {
  const { control, handleSubmit, watch, setValue, reset } = useForm();
  const [loadingInstructors, setLoadingInstructors] = useState(false);
  const [loadingTerms, setLoadingTerms] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [loadingTFGs, setLoadingTFGs] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState('');
  const [terms, setTerms] = useState([]);
  const [TFGs, setTFGs] = useState([]);
  const [courts, setCourts] = useState([]);
  const notification = useNotification();
  const { requestId, date } = watch();

  useEffect(() => {
    async function retrieveInstructors() {
      try {
        setLoadingInstructors(true);
        // TODO: Add term?
        const result = await getTFGDefenseDirectors().then(
          res => res.map(item => ({ label: item.name, value: item.externalId }))
        );
        if (!result) throw new Error();
        setInstructors(result);
      } catch {
        // Notification
      } finally {
        setLoadingInstructors(false);
      }
    }

    if (editing && !instructors.length) retrieveInstructors();
  }, [editing, instructors.length]);

  useEffect(() => {
    if (editing) {
      const { id, requestId, chairman, secretary, vocal, termId, location } = editing;
      const resetValues = { id, requestId, chairman, secretary, vocal, termId, location };
      const timespan = editing.start;
      const date = dateToDayMonthYear(timespan);
      const time = dateToHourMinute(timespan);
      reset({ ...resetValues, date, time });
    }
  }, [editing, reset])

  useEffect(() => {
    if (!visible) {
      setTimeout(() => reset({ requestId: null, date: '', time: '' }), 500);
    }
  }, [reset, visible])

  useEffect(() => {
    if (!editing) setValue('date', '');
  }, [editing, requestId, setValue]);
  
  const [timespans, availableTimes] = useMemo(() => {
    if (!courts.length) return [[], []];

    let timespans = [];

    // First, merge all the timespans
    courts.forEach(court => {
      timespans = [...timespans, ...court.timeSpans]
    });

    // Remove timespans if they are reserved (unless editing the same timespan)
    timespans = timespans.filter(timespan =>
      !timespan.reserved || (timespan.reserved && timespan.id === editing?.timeSpanId)
    );

    if (!timespans.length) {
      notification('error', 'No se han encontrado franjas horarias disponibles, por favor contacta con secretaría')
      return [[], []];
    }

    // Now, sort these timespans by start date
    const sortedTimespans = timespans.sort((a, b) => {
      const dateA = new Date(a.start);
      const dateB = new Date(b.start);
      return dateA - dateB;
    });

    // Finally, reduce the array to get the available times grouped by day
    const availableTimes = sortedTimespans.reduce((acc, timespan) => {
      const day = dateToDayMonthYear(timespan.start);
      const hour = dateToHourMinute(timespan.start);
      if (!acc[day]) acc[day] = [];
      acc[day].push(hour);
      return acc;
    }, {});

    return [timespans, availableTimes];
  }, [courts, editing, notification]);

  useEffect(() => {
    if (editing) {
      const time = dateToHourMinute(editing.start);
      availableTimes[date]?.includes(time)
        ? setValue('time', time)
        : setValue('time', '');
    } else {
      setValue('time', '');
    }
  }, [editing, date, setValue, availableTimes]);

  useEffect(() => {
    async function retrieveTFGs() {
      try {
        setLoadingTFGs(true);
        const result = await getTFGRequests(
          { availableForDefense: true }, { page: 0, size: 50 }
        ).then(res => res.content.map(
          req => {
            const { provisionalTitle, degreeId, degreeName, id } = req;
            if (provisionalTitle) return { value: id, label: `${provisionalTitle} - ${degreeName}`, degreeId };
            return { value: id, label: degreeName, degreeId };
          }
        ));
        if (!result) throw new Error();
        setTFGs(result);
      } catch {
        notification('error', 'Ha habido un error al obtener las solicitudes pendientes de defensa');
      } finally {
        setLoadingTFGs(false);
      }
    }

    visible && !TFGs?.length && !editing && retrieveTFGs();
  }, [notification, visible, TFGs, editing]);

  useEffect(() => {
    async function retrieveCourts(requestId, academicYear) {
      try {
        setApplicationLoading(true);
        const result = await getTFGDefenseStudentCourts(requestId, academicYear);
        if (!result) throw new Error();
        setCourts(result);
      } catch {
        notification('error', 'Ha habido un error al obtener las solicitudes pendientes de defensa');
      } finally {
        setApplicationLoading(false);
      }
    }

    if (requestId && selectedTerm) retrieveCourts(requestId, selectedTerm);
    else setCourts([]);
  }, [notification, requestId, selectedTerm]);

  useEffect(() => {
    async function retrieveTerms() {
      try {
        setLoadingTerms(true);
        const request = TFGs.find(tfg => tfg.value === requestId);
        console.log(requestId, request, TFGs)
        if (!request) throw new Error();
        const result = await getTFGTerms('DEFENSE', undefined, selectedTerm, request.degreeId).then(
          res => res.map(item => ({ value: item.id, label: item.name }))
        );
        if (!result) throw new Error();
        if (!result.length) {
          notification('error', 'No se han encontrado periodos de defensa activos');
          return;
        }
        setTerms(result);
      } catch {
        notification('error', 'Ha habido un error al obtener las convocatorias');
      } finally {
        setLoadingTerms(false);
      }
    }

    visible && !editing && !!selectedTerm && !!requestId && retrieveTerms();
  }, [notification, editing, visible, selectedTerm, TFGs, requestId]);

  useEffect(() => {
    setValue('termId', null);
  }, [selectedTerm, setValue])

  const onSubmit = async data => {
    try {
      setApplicationLoading(true);
      const { date, time } = data;
      const dateUTC = dateToUTC(`${date} ${time}`, 'DD/MM/YYYY HH:mm').substring(0, 19) + 'Z';
      let selected;
      if (editing && dateUTC === editing.start) selected = editing.timeSpanId;
      else selected = timespans.find(timespan => timespan.start === dateUTC).id;
      const court = courts.find(court => court.timeSpans.some(timespan => timespan.id === selected));
      const service = editing ? updateTFGDefenseRequest : createTFGDefenseRequest;
  
      let body = {
        ...data,
        academicYearId: selectedTerm,
        requestId,
        courtId: court.id,
        timeSpanId: selected,
      };

      const result = await service(body);
      if (!result) throw new Error();
      notification('success', 'La solicitud de defensa se ha creado correctamente');
      onSuccess(!!editing, result);
    } catch {
      notification('error', 'Ha habido un error al crear la solicitud de defensa')
    } finally {
      setApplicationLoading(false);
    }
  }

  const editingJSX = loadingInstructors
    ? (
      <Grid item xs={12} className="spinner-container">
        <p>Recuperando instructores...</p>
        <CircularProgress size={24} />
      </Grid>
    ) : (
      <>
        <Grid item xs={12}>
          <InputAutoComplete
            name="chairman"
            control={control}
            options={instructors}
            label="Presidente"
            loading={loadingInstructors}
            rules={required}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputAutoComplete
            name="secretary"
            control={control}
            options={instructors}
            label="Secretario"
            loading={loadingInstructors}
            rules={required}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputAutoComplete
            name="vocal"
            control={control}
            options={instructors}
            label="Vocal"
            loading={loadingInstructors}
            rules={required}
          />
        </Grid>
      </>
    );

  return (
    <Dialog
      className="defense-request-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{`${editing ? 'Editar' : 'Crear'} solicitud de defensa`}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            { !editing && (
              <>
                <Grid item xs={12}>
                  <TFGTermSelector
                    selectedTerm={selectedTerm}
                    setSelectedTerm={setSelectedTerm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputAutoCompleteValue
                    name="requestId"
                    control={control}
                    options={TFGs}
                    label="TFG/TFM a defender"
                    loading={loadingTFGs}
                    rules={required}
                    // disabled={!terms.length}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputAutoCompleteValue
                    name="termId"
                    control={control}
                    options={terms}
                    label="Convocatoria"
                    loading={loadingTerms}
                    rules={required}
                    disabled={!requestId}
                  />
                </Grid>
              </>
            )}
            <Grid item md={6} xs={12}>
              <InputSelectSimple
                name="date"
                control={control}
                options={Object.keys(availableTimes) ?? []}
                label="Fecha"
                rules={required}
                disabled={!requestId}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputSelectSimple
                name="time"
                control={control}
                options={availableTimes[date] ?? []}
                label="Hora"
                rules={required}
                disabled={!date}
              />
            </Grid>
            { editing?.defenseRequestStatus === "ACCEPTED" && (
              <Grid item xs={12}>
                <InputText
                  name="location"
                  control={control}
                  label="Lugar de defensa"
                  placeholder="Lugar donde se realizará la defensa"
                />
              </Grid>
            )}
            { editing && editingJSX }
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TFGDefenseDialog