export const mockedTFGTitulations = [
  {
    "externalId": "TITULATION_1",
    "name": "Primera titulación"
  },
  {
    "externalId": "TITULATION_2",
    "name": "Segunda titulación"
  },
  {
    "externalId": "UCAV",
    "name": "Histórico mensajería",
  },
  {
    "externalId": "CIENC_AMB",
    "name": "Grado en Ciencias Ambientales",
  }
];

export const mockedTFGInstances = [
  {
    "id": "2235171187a55264fb4c7488",
    "name": "Ciencias ambientales",
    "degreeId": "CIENC_AMB",
    "degreeName": "Grado en Ciencias Ambientales",
    "termId": "22_23",
    "termName": "22/23",
    "start": "2022-02-15T18:35:24Z",
    "end": "2024-02-15T18:35:24Z",
    "assignedCoords": [
      {
        "externalId": "profeucav",
        "name": "Profesor UCAV (Pruebas)"
      }
    ]
  }
];

export const mockedTFGSubjects = [
  {
    "id": "6345471187a55264fb96b279",
    "name": "Etnobotánica",
    "active": true,
    "academicYearId": 2,
    "assignedDegrees": [
      {
        "externalId": "UCAV",
        "name": "Histórico mensajería",
      },
      {
        "externalId": "CIENC_AMB",
        "name": "Grado en Ciencias Ambientales",
      }
    ],
    "assignedManagers": [
      {
        "externalId": "dmperez",
        "name": "Dulce María Pérez de Blas",
      },
      {
        "externalId": "profeucav",
        "name": "Profesor UCAV (Pruebas)"
      }
    ]
  },
  {
    "id": "6345471187a55264fb96b274",
    "name": "Informática",
    "active": false,
    "academicYearId": 2,
    "assignedDegrees": [
      {
        "externalId": "UCAV",
        "name": "Histórico mensajería",
      },
      {
        "externalId": "CIENC_AMB",
        "name": "Grado en Ciencias Ambientales",
      }
    ],
    "assignedManagers": []
  }
];

export const mockedTFGInstructors = [
  {
    "externalId": "dmperez",
    "name": "Dulce María Pérez de Blas",
  },
  {
    "externalId": "supervisor_examenes",
    "name": "Supervisor Examen",
  },
  {
    "externalId": "emunoz",
    "name": "Ester Muñoz",
  },
  {
    "externalId": "supervisorclases2",
    "name": "Supervisor Clases (Soporte Técnico)",
  },
  {
    "externalId": "coord1",
    "name": "coord1 coord1"
  },
  {
    "externalId": "profeucav",
    "name": "Profesor UCAV (Pruebas)"
  }
];

export const mockedTFGRequests = [
  {
    id: "TFG_REQUEST_1",
    userId: 'cesarelea',
    userName: 'César',
    dni: 'CESAR_DNI',
    email: 'cesar@elearningmedia.es',
    degreeId: "TITULATION_1",
    degreeName: "TITULATION #1",
    termId: 1,
    priority: 4,
    areaId: "SUBJECT_1",
    areaName: "ÁREA TEMÁTICA #1",
    instructorId: "INSTRUCTOR_1",
    instructorName: "INSTRUCTOR #1",
    created: "2022-10-07T10:07:55Z",
    status: "OPEN"
  },
  {
    id: "TFG_REQUEST_2",
    userId: 'marc',
    userName: 'Marc',
    dni: 'MARC_DNI',
    email: 'marc@elearningmedia.es',
    degreeId: "TITULATION_1",
    degreeName: "TITULATION #1",
    termId: 1,
    priority: 2,
    areaId: "SUBJECT_2",
    areaName: "ÁREA TEMÁTICA #2",
    instructorId: "INSTRUCTOR_2",
    instructorName: "INSTRUCTOR #2",
    created: "2022-10-05T08:00:00Z",
    status: "ACCEPTED"
  },
  {
    id: "TFG_REQUEST_3",
    userId: 'cesarelea',
    userName: 'César Expósito',
    dni: 'CESAR_DNI',
    email: 'cesar@elearningmedia.es',
    degreeId: "TITULATION_1",
    degreeName: "TITULATION #1",
    areaId: "6345471187a55264fb96b279",
    areaName: "ÁREA TEMÁTICA #2",
    termId: 1,
    priority: 3,
    instructorId: "dmperez",
    instructorName: "INSTRUCTOR #2",
    created: "2022-10-05T08:00:00Z",
    status: "OPEN",
    provisionalTitle: 'Título provisional',
  },
  {
    id: "TFG_REQUEST_4",
    userId: 'cesarelea',
    userName: 'César Expósito',
    dni: 'CESAR_DNI',
    email: 'cesar@elearningmedia.es',
    degreeId: "TITULATION_1",
    degreeName: "TITULATION #1",
    termId: 4,
    priority: 1,
    areaId: "6345471187a55264fb96b279",
    areaName: "ÁREA TEMÁTICA #2",
    instructorId: "dmperez",
    instructorName: "INSTRUCTOR #2",
    created: "2022-10-05T08:00:00Z",
    status: "OPEN",
    provisionalTitle: "Real hasta la muerte"
  }
];

export const mockedTFGRequestsWithPrefferences = [
  { ...mockedTFGRequests[2], priority: 1 },
  { ...mockedTFGRequests[3], priority: 2 },
]

export const mockedTFGUsers = [
  {
    dni: '24636912R',
    name: 'César Andrés',
    surnames: 'Expósito Femenia',
    email: 'cesar@elearningmedia.es',
    mobilePhone: '635248966'
  },
  {
    dni: '27885302V',
    name: 'Marc',
    surnames: 'Estupiña',
    email: 'marc@elearningmedia.es',
    mobilePhone: '682455895'
  }
];

// export const mockedTFGTerms = [
//   {
//     externalId: "AVEX",
//     name: "Aula Virtual (Exámenes)"
//   },
//   {
//     externalId: "EXJUN_2021",
//     name: "Exámenes Convocatoria Junio 2021"
//   },
//   {
//     externalId: "EXAM_JUN2122",
//     name: "Exámenes convocatoria Junio 2022"
//   }
// ];

export const mockedTFGManagers = [
  {
    externalId: "profeucav",
    name: "Profesor UCAV (Pruebas)"
  },
  {
    externalId: "marcucav",
    name: "Marc Estupiña"
  },
  {
    externalId: "cesarelea",
    name: "César Expósito"
  }
];

export const mockedTFGManagerInfo = {
  externalId: "profeucav",
  instructorName: "Profesor UCAV (Pruebas)",
  yearlyAllocations: [
    {
      academicYearId: 1,
      yearlyLimit: 5,
      degreeAllocations: [
        {
          degreeId: "TITULATION_1",
          limit: 3,
          requestIds: []
        },
        {
          degreeId: "TITULATION_2",
          limit: 3,
          requestIds: ["TFG_REQUEST_2"]
        }
      ],
    },
    {
      academicYearId: 2,
      yearlyLimit: 4,
      degreeAllocations: [
        {
          degreeId: "TITULATION_1",
          limit: 2,
          requestIds: []
        },
        {
          degreeId: "TITULATION_2",
          limit: 2,
          requestIds: ["TFG_REQUEST_2"]
        }
      ],
    }
  ],
};

export const mockedTFGCoordInstances = [
  {
    id: "CIENC_AMB",
    name: "Ciencias ambientales",
    degreeName: 'Grado en Ciencias de la Naturaleza',
    termName: '22/23'
  }
];

export const mockedTFGYears = [
  {
    id: 1,
    name: 'Año académico 2022/2023',
    active: false,
  },
  {
    id: 2,
    name: 'Año académico 2023/2024',
    active: true,
  },
]

export const mockedTFGTerms = [
  {
    id: 1,
    academicYear: {
      id: 1,
      name: 'Año académico 2022/2023',
      active: false,
    },
    name: "Convocatoria Febrero 2022/2023",
    start: "2022-10-01T07:00:00Z",
    end: "2023-01-31T22:00:00Z",
    type: 'AREA',
    assignedDegrees: [
      {
        externalId: "UCAV",
        name: "Histórico mensajería",
      },
      {
        externalId: "CIENC_AMB",
        name: "Grado en Ciencias Ambientales",
      }
    ],
  },
  {
    id: 20,
    academicYear: {
      id: 1,
      name: 'Año académico 2022/2023',
      active: false,
    },
    name: "Convocatoria defensa Febrero 2022/2023",
    start: "2023-02-01T07:00:00Z",
    end: "2023-02-18T19:00:00Z",
    type: 'DEFENSE',
    assignedDegrees: [],
  },
  {
    id: 2,
    academicYear: {
      id: 1,
      name: 'Año académico 2022/2023',
      active: false,
    },
    name: "Convocatoria Junio 2022/2023",
    start: "2022-10-01T07:00:00Z",
    end: "2023-05-31T22:00:00Z",
    type: 'AREA',
    assignedDegrees: [],
  },
  {
    id: 21,
    academicYear: {
      id: 1,
      name: 'Año académico 2022/2023',
      active: false,
    },
    name: "Convocatoria defensa Junio 2022/2023",
    start: "2023-06-01T07:00:00Z",
    end: "2023-06-18T19:00:00Z",
    type: 'DEFENSE',
    assignedDegrees: [],
  },
  {
    id: 3,
    academicYear: {
      id: 1,
      name: 'Año académico 2022/2023',
      active: false,
    },
    name: "Convocatoria Septiembre 2022/2023",
    start: "2022-10-01T07:00:00Z",
    end: "2023-08-31T22:00:00Z",
    type: 'AREA',
    assignedDegrees: [],
  },
  {
    id: 4,
    academicYear: {
      id: 2,
      name: 'Año académico 2023/2024',
      active: true,
    },
    name: "Convocatoria Febrero 2023/2024",
    start: "2023-10-01T07:00:00Z",
    end: "2024-01-31T22:00:00Z",
    type: 'AREA',
    assignedDegrees: [],
  },
  {
    id: 5,
    academicYear: {
      id: 2,
      name: 'Año académico 2023/2024',
      active: true,
    },
    name: "Convocatoria Junio 2023/2024",
    start: "2023-10-01T07:00:00Z",
    end: "2024-05-31T22:00:00Z",
    type: 'AREA',
    assignedDegrees: [],
  },
  {
    id: 6,
    academicYear: {
      id: 2,
      name: 'Año académico 2023/2024',
      active: true,
    },
    name: "Convocatoria Septiembre 2023/2024",
    start: "2023-10-01T07:00:00Z",
    end: "2024-08-31T22:00:00Z",
    type: 'AREA',
    assignedDegrees: [],
  },
];

export const mockedTFGCourt = [
  {
    id: 'TRIBUNAL_1',
    name: 'Tribunal 1',
    // members: ['dmperez', 'emunoz'],
    chairman: "dmperez",
    chairmanName: "Dulce María Pérez de Blas",
    secretary: "profeucav",
    secretaryName: "Profesor UCAV (Pruebas)",
    vocal: "emunoz",
    vocalName: "Ester Muñoz",
    timeSpans: [
      { id: 1, start: '2023-06-05T09:00:00Z', reserved: false },
      { id: 2, start: '2023-06-05T11:00:00Z', reserved: false },
      { id: 3, start: '2023-06-05T16:00:00Z', reserved: false },
      { id: 4, start: '2023-06-07T09:00:00Z', reserved: false },
      { id: 5, start: '2023-06-07T11:00:00Z', reserved: false },
      { id: 6, start: '2023-06-07T16:00:00Z', reserved: false },
      { id: 7, start: '2023-06-09T09:00:00Z', reserved: false },
      { id: 8, start: '2023-06-09T11:00:00Z', reserved: false },
      { id: 9, start: '2023-06-09T16:00:00Z', reserved: false },
    ]
  },
  {
    id: 'TRIBUNAL_2',
    name: "Tribunal 2",
    // members: ['dmperez', 'profeucav'],
    chairman: "dmperez",
    chairmanName: 'Dulce María Pérez de Blas',
    secretary: "coord1",
    secretaryName: 'Coordinador 1',
    vocal: "profeucav",
    vocalName: 'Profesor UCAV',
    degreeId: 'TITULATION_1',
    timeSpans: []
  }
]

export const mockedTFGDefenseRequests = [
  {
    id: "6475ff51af11a953d3ffc503",
    requestId: "6475feb6af11a953d3ffc502",
    provisionalTitle: "Título provisional",
    degreeId: "MID_SEMIPRESENCIAL",
    termId: "6475fea3af11a953d3ffc501",
    areaId: "6475fe5faf11a953d3ffc500",
    instructorId: "supervisorclases1",
    instructorName: 'Supervisor de TFG',
    userId: "administrator",
    courtId: "6475fd37af11a953d3ffc4fb",
    start: "2023-06-05T09:00:00Z",
    defenseRequestStatus: "OPEN",
    chairman: "dmperez",
    chairmanName: "Dulce María Pérez de Blas",
    secretary: "profeucav",
    secretaryName: "Profesor UCAV (Pruebas)",
    vocal: "emunoz",
    vocalName: "Ester Muñoz",
    dni: '2768299A',
    userName: 'cesarelea',
    email: 'cesar@elearningmedia.es',
    phone: '635248966',
  }
];

export const mockedTFGDefenseStudentCourts = [
  {
    id: "6475fd37af11a953d3ffc4fb",
    name: "Tribunal 1",
    chairman: null,
    secretary: null,
    vocal: null,
    timeSpans: [
      {
        id: "6475fd47af11a953d3ffc4fc",
        start: "2023-06-05T09:00:00Z",
        reserved: false
      },
      {
        id: "6475fd47af11a953d3ffc4fk",
        start: "2023-06-06T10:00:00Z",
        reserved: false
      },
      {
        id: "6475fd47af11a953d3ffc4fe",
        start: "2023-06-05T11:00:00Z",
        reserved: false
      },
      {
        id: "6475fd47af11a953d3ffc4fd",
        start: "2023-06-05T16:00:00Z",
        reserved: false
      },
    ]
  },
  {
    id: "8475fd37af11a953d3ffc4fz",
    name: "Tribunal 2",
    chairman: null,
    secretary: null,
    vocal: null,
    timeSpans: [
      {
        id: "8475fd47af11a953d3ffc4fw",
        start: "2023-06-05T10:00:00Z",
        reserved: false
      },
      {
        id: "8475fd47af11a953d3ffc4fx",
        start: "2023-06-05T12:00:00Z",
        reserved: false
      },
      {
        id: "8475fd47af11a953d3ffc4fv",
        start: "2023-06-06T09:00:00Z",
        reserved: false
      },
      {
        id: "8475fd47af11a953d3ffc4fy",
        start: "2023-06-05T17:00:00Z",
        reserved: false
      },
    ]
  },
]

export const mockedTFGPriorityGrades = [
  {
    userId: 'cesarelea',
    degreeId: 'CIENC_AMB',
    priority: 7.85,
  },
  {
    userId: 'marc',
    degreeId: 'CIENC_AMB',
    priority: 7.25,
  },
  {
    userId: 'cesarelea',
    degreeId: 'CIENC_NAT',
    priority: 6,
  },
  {
    userId: 'alumnoucav',
    degreeId: 'REDES',
    priority: 4,
  },
  {
    userId: 'alumnoucav',
    degreeId: 'CIENC_AMB',
    priority: 4.55,
  },
];