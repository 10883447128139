import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { Button, Icon, Tooltip, IconButton } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import TFGSubjectsTable from './TFGSubjectsTable'
import TFGSubjectDialog from './TFGSubjectDialog';
import { copyToClipboard } from '../../../Utils/utils';
import { useNotification } from '../../../Utils/hooks';
import { deleteTFGSubject, getTFGAreas, updateTFGSubject, uploadTFGAreasCSV, downloadTFGAreasCSV } from '../../../services/TFG/TFGService';
import TFGTermSelector from '../TFGTermSelector/TFGTermSelector';

const CSVKeys = ['academicYearId', 'name', 'assignedDegrees', 'assignedManagers'];

const TFGSubjects = ({ admin }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [showHidden, setShowHidden] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState('');
  const fileUploadRef = useRef(null);
  const notification = useNotification();
  const confirm = useConfirm();

  const mappedSubjects = useMemo(() => subjects.map(
    area => ({ ...area, assignedManagerIds: area.assignedManagers.map(manager => manager.externalId) })
  ), [subjects]);

  const retrieveSubjects = useCallback(async (term, showHidden) => {
    try {
      setApplicationLoading(true);
      const result = await getTFGAreas(term, showHidden);
      if (!result) throw new Error();
      setSubjects(result);
    } catch {
      notification('error', 'Ha habido un error al obtener las áreas temáticas para el año académico seleccionado');
    } finally {
      setApplicationLoading(false);
    }
  }, [notification]);

  useEffect(() => {
    if (selectedTerm) retrieveSubjects(selectedTerm, showHidden);
  }, [retrieveSubjects, selectedTerm, showHidden]);

  const onUploadClick = event => {
    event.preventDefault();

    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  }

  const onDownloadCSVClick = () => {
    downloadTFGAreasCSV();
  }

  const onCopyClick = () => {
    copyToClipboard(CSVKeys.join('|'));
    notification('info', 'Las cabeceras se han copiado al portapapeles');
  }

  const onFilesChange = async event => {
    const files = event.target.files;

    if (files) {
      const file = files.item(0);
      if (file) {
        try {
          setApplicationLoading(true);
          await uploadTFGAreasCSV(file);
          notification('success', 'Las áreas temáticas se han creado correctamente');
          retrieveSubjects();
        } catch {
          notification('error', 'Ha habido un error al subir o interpretar el CSV');
        } finally {
          setApplicationLoading(false);
        }
      }
    }
  }

  const onHideDialog = () => {
    setShowDialog(false);
    setSelectedSubject(null);
  }

  const onDialogSuccess = (isEdit, result) => {
    setShowDialog(false);
    setSelectedSubject(null);
  
    setSubjects(prev => {
      const newSubjects = [...prev];
      if (isEdit) {
        const index = newSubjects.findIndex(subj => subj.id === result.id);
        newSubjects[index] = result;
      } else {
        newSubjects.push(result)
      }
      return newSubjects;
    })
  }

  const onEditClick = id => {
    const subject = mappedSubjects.find(sub => sub.id === id);
    setSelectedSubject(subject);
    setShowDialog(true);
  }

  const onDeleteClick = id => {
    const subject = mappedSubjects.find(sub => sub.id === id);
    if (!subject) return;

    confirm({
      title: 'Eliminar área temática',
      description: `Atención, esta acción eliminará el área temática ${subject.name}, ¿estás seguro de que deseas realizar esta acción?`,
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          await deleteTFGSubject(id);
          setSubjects(prev => prev.filter(area => area.id !== id))
        } catch {
          notification('error', 'Ha habido un error al eliminar el área temática');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const onToggleVisibilityClick = id => {
    const subject = mappedSubjects.find(sub => sub.id === id);
    if (!subject) return;

    confirm({
      title: `${!subject.active ? 'Activar' : 'Desactivar'} área temática`,
      description: `Atención, esta acción ${!subject.active ? 'mostrará / activará' : 'ocultará / desactivará'} el área temática ${subject.name}, ¿deseas continuar?`,
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          await updateTFGSubject({
            ...subject,
            active: !subject.active,
            assignedManagerIds: subject.assignedManagers.map(inst => inst.externalId),
            assignedDegreeIds: subject.assignedDegrees.map(titul => titul.externalId),
          });
          setSubjects(prev => {
            const newSubjects = [...prev];
            const index = newSubjects.findIndex(subj => subj.id === id);
            newSubjects[index] = { ...newSubjects[index], active: !newSubjects[index].active };
            return newSubjects;
          })
        } catch {
          notification('error', `Ha habido un error al ${!subject.active ? 'activar' : 'desactivar'} el área temática`);
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const TermSelector = <TFGTermSelector selectedTerm={selectedTerm} setSelectedTerm={setSelectedTerm} />

  if (!selectedTerm) return TermSelector;

  return (
    <>
      {TermSelector}
      { !!admin && (
        <div className="upload-csv">
          <p className="disclaimer">
            Cargue un archivo con las cabeceras:&nbsp;
            <i>{CSVKeys.join(' | ')}</i>
            <br /><br />
            Puedes utilizar el botón inferior para copiar las cabeceras requeridas al portapapeles
          </p>
          <div className="upload-csv__actions">
            <Tooltip title="Copiar cabeceras al portapapeles" aria-label="copy">
              <IconButton aria-label="copy">
                <Icon className="copy-icon" onClick={onCopyClick}>
                  content_copy
                </Icon>
              </IconButton>
            </Tooltip>
            <input
              type="file"
              onChange={onFilesChange}
              multiple={false}
              style={{ display: 'none' }}
              ref={fileUploadRef}
              accept=".csv"
            />
            <Button
              onClick={onUploadClick}
              variant="contained"
              color="primary"
            >
              Subir CSV
            </Button>
            <Button
              onClick={onDownloadCSVClick}
              variant="contained"
              color="secondary"
            >
              Descargar CSV
            </Button>
          </div>
        </div>
      ) }
      <div className="subject-actions">
        <Button
          onClick={() => setShowHidden(prev => !prev)}
          variant="contained"
          color="secondary"
          style={{ marginRight: '1em' }}
        >
          { `${showHidden ? 'Ocultar' : 'Mostrar'} áreas temáticas ocultas` }
        </Button>
        <Button
          onClick={() => setShowDialog(true)}
          variant="contained"
          color="primary"
        >
          Crear nueva área temática
        </Button>
      </div>
      <TFGSubjectDialog
        visible={showDialog}
        onHide={onHideDialog}
        subject={selectedSubject}
        onSuccess={onDialogSuccess}
        selectedTerm={selectedTerm}
      />
      <TFGSubjectsTable
        subjects={mappedSubjects}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        onToggleVisibilityClick={onToggleVisibilityClick}
      />
    </>
  )
}

export default TFGSubjects